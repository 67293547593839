import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom"; 
import WebApps from './WebApps'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function App() {
  return (
    <Router>
    <div className="flex flex-col min-h-screen">
      {/* Top Navigation */}
      <nav className="bg-blue-600 text-white py-4 px-8 sticky top-0 shadow-lg z-50">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">Yellow Ray Development Portal</h1>
          <ul className="flex space-x-10">
            <li>
            <Link to="/" className="hover:text-yellow-300 transition font-bold">Home</Link> {/* Use Link here */}
            </li>
            <li>
              <a
                href="https://yellowray.solutions"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-yellow-300 transition font-bold"
              >
                Solutions
              </a>
            </li>
            <li>
              <a
                href="https://www.yrcgi.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-yellow-300 transition font-bold"
              >
                YRCGI Main Website
              </a>
            </li>
            <li>
            <Link to="/webapps" className="hover:text-yellow-300 transition font-bold">Demos</Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* Main Content */}
      <main className="flex-grow bg-gray-100 text-gray-800 py-10">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6">
            Welcome!
          </h2>
          <p className="text-lg mb-10">
            A place to explore our solutions, test web application demos, and more!
          </p>
          <div className="flex justify-center gap-6">
            <a
              href="https://yellowray.solutions"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition"
            >
              Visit Solutions Site
            </a>
            <a
              href="https://www.yrcgi.com"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-green-500 text-white rounded-lg shadow-lg hover:bg-green-600 transition"
            >
              Visit Main Website
            </a>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-6 mt-auto">
        <div className="container mx-auto text-center">
        <p className="mb-2">&copy; 2024-{new Date().getFullYear()} Yellow Ray Consulting Group Inc. All rights reserved.</p>
          <div className="flex justify-center space-x-4">
            <a
              href="https://www.linkedin.com/company/yellowray-consulting-group/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </a>
            {/* <a
              href="https://github.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              GitHub
            </a> */}
          </div>
        </div>
      </footer>
      <Routes>
      <Route path="/" element={<div>Home Page Content</div>} />
          <Route path="/webapps" element={<WebApps />} />
        </Routes>
    </div>
    </Router>
  );
}

export default App;