import { React, useState } from "react";
import { Link } from "react-router-dom";

function WebApps() {
  const [isComingSoon, setIsComingSoon] = useState(true); // Toggle this value to show/hide buttons

  return (
    <div className="container mx-auto py-10 text-center">
      <h2 className="text-3xl font-bold mb-6">Available Web Application Demos</h2>
      <p className="text-lg mb-10">Explore a web app demo below</p>

      {/* If isComingSoon is true, show the Coming Soon message */}
      {isComingSoon ? (
        <p className="text-xl text-gray-600">Coming Soon...</p>
      ) : (
        <div className="flex justify-center gap-6">
          {/* CiparsApp Button */}
          <Link
            to="https://ciparsapp.yourdomain.dev/login"
            target="_blank"
            rel="noopener noreferrer"
            className="px-6 py-3 bg-purple-500 text-white rounded-lg shadow-lg hover:bg-purple-600 transition"
          >
            Admin App
          </Link>

          {/* Database App Button */}
          <Link
            to="https://anotherapp.yourdomain.dev/login"
            target="_blank"
            rel="noopener noreferrer"
            className="px-6 py-3 bg-purple-500 text-white rounded-lg shadow-lg hover:bg-purple-600 transition"
          >
            Database App
          </Link>
        </div>
      )}
    </div>
  );
}

export default WebApps;